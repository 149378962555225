import { useState } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import { Divider, Drawer, Button, Link, Logo, Box, Typography } from "@repo/ui"
import CloseIcon from "@mui/icons-material/Close"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Image from "next/image"
import TopMenuContentItem from "./TopMenuContentItem"
import styles from "./TopMenuMobile.module.scss"

const TopMenuMobile = () => {
  const [open, setOpen] = useState(false)
  const [screenMode, setScreenMode] = useState("start")

  const onDrawerClose = () => {
    setOpen(false)
  }
  const onDrawerOpen = () => {
    setOpen(true)
  }

  const contentItems = [
    {
      label: "Home",
      route: "/",
    },
    {
      label: "Blog",
      route: "/blog",
    },
    {
      label: "Destinations",
      route: "/destinations",
    },
    {
      label: "About",
      route: "/about",
    },
    {
      label: "Contact",
      route: "/contact",
    },
  ]

  const contentItemsMapper = ({ label, icon, onClick, route, target }) => {
    if (route) {
      return (
        <Link
          key={label}
          to={route}
          className={styles["top-menu-content-item-link"]}
          target={target}
        >
          <TopMenuContentItem label={label} icon={icon} onClick={onClick} hasRoute={Boolean(route)} />
        </Link>
      )
    }

    return <TopMenuContentItem key={label} label={label} icon={icon} onClick={onClick} />
  }

  const onResetModeClick = () => {
    setScreenMode("start")
  }

  return (
    <div>
      <div className={styles["hamburger-icon"]}>
        <Button
          edge="start"
          aria-label="open drawer"
          style={{
            color: "#fff",
          }}
          onClick={onDrawerOpen}
          variant="text"
        >
          <MenuIcon fontSize="large" />
        </Button>
      </div>

      <Drawer
        anchor="right"
        open={open}
        onClose={onDrawerClose}
        onOpen={onDrawerOpen}
        header={
          <>
            <Box style={{ display: "flex", alignItems: "center" }}>
              {screenMode !== "start" ? (
                <ChevronLeftIcon
                  onClick={onResetModeClick}
                  style={{ color: "#fff", width: 32 }}
                  className={styles["menu-item-icon"]}
                />
              ) : (
                <div style={{ width: 32 }}></div>
              )}
              <Link to="/" style={{ flex: 2, display: "flex", justifyContent: "center" }}>
                <Logo Image={Image} />
              </Link>

              <Button onClick={onDrawerClose} variant="text">
                <CloseIcon style={{ color: "#fff" }} />
              </Button>
            </Box>
            <Divider style={{ marginTop: "0.5rem", backgroundColor: "#fff" }} />
          </>
        }
      >
        <Box
          backgroundColor="var(--background-color)"
          padding="1rem"
          margin="0 0 2rem 0"
        >
          <Box style={{ marginTop: "2rem", flex: 1 }}>
            <Typography variant="h4" className={styles["menu-items-title"]} margin="0 0 1rem 0">
              Content
            </Typography>

            {contentItems.map(contentItemsMapper)}
          </Box>
        </Box>
      </Drawer>
    </div>
  )
}

export default TopMenuMobile
