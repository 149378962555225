import type { ArticlePrefix } from '@repo/utils/articleUtils';

const subjects = [
    {
        id: 1,
        imageUrl: '/images/destinations/seychelles.png',
        urlSlug: 'seychelles',
        subject: 'Seychelles',
        description:
            'Explore the Seychelles, a group of 115 islands in the Indian Ocean known for their stunning beaches, lush rainforests, and vibrant coral reefs.',
    },
    {
        id: 2,
        imageUrl: '/images/destinations/aruba.png',
        urlSlug: 'aruba',
        subject: 'Aruba',
        description:
            'Visit Aruba, a Caribbean island known for its white sandy beaches, turquoise waters, and vibrant culture.',
    },
    {
        id: 3,
        imageUrl: '/images/destinations/maldives.png',
        urlSlug: 'maldives',
        subject: 'Maldives',
        description:
            'Discover the Maldives, a tropical paradise with crystal-clear waters, white sandy beaches, and luxurious resorts.',
    },
];

export const availableTabs: ArticlePrefix[] = ['blog'];

const appCategories: any = {
    subjects,
};

export default appCategories;
