'use client';

import {
    Box,
    Button,
    CardCarousel,
    CardWrapper,
    FeaturedImage,
    Grid,
    Link,
    Typography,
} from '@repo/ui';
import Image from 'next/image';

const DestinationCard = ({
    item,
    Image,
    gridSize = { xs: 12, sm: 6, md: 6, lg: 4 },
    lazyLoad = true,
}: any) => (
    <Grid item {...gridSize}>
        <Link to={item.url} style={{ position: 'relative', display: 'flex', height: '100%' }}>
            <CardWrapper
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FeaturedImage
                    Image={Image}
                    width={560}
                    height={420}
                    src={item.imageSrc}
                    alt={item.imageAlt}
                    lazyLoad={lazyLoad}
                />

                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                    }}
                    padding="1rem"
                >
                    <Typography variant="h3" fontSize="1.5rem !important" margin="0 0 1rem 0">
                        {item.title}
                    </Typography>

                    <Typography>{item.description}</Typography>
                </Box>
            </CardWrapper>
        </Link>
    </Grid>
);

const DestinationCardCarousel = ({
    lazyLoad,
    showAll,
}: {
    lazyLoad: boolean;
    showAll?: boolean;
}) => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <CardCarousel
                title="Top Destinations"
                items={[
                    {
                        id: 1,
                        title: 'Seychelles',
                        description:
                            "Seychelles is an archipelago of 115 islands in the Indian Ocean, off East Africa. It's home to numerous beaches, coral reefs and nature reserves, as well as rare animals such as giant Aldabra tortoises.",
                        imageSrc: '/images/destinations/seychelles.png',
                        imageAlt: 'Seychelles',
                        url: '/seychelles/blog',
                    },
                    {
                        id: 2,
                        title: 'Aruba',
                        description:
                            'Aruba, a tiny Dutch Caribbean island off the coast of Venezuela, has dry, sunny weather, blond beaches and gentle surf. Constant trade winds bring cool breezes and cause the divi-divi trees to slope southwesterly.',
                        imageSrc: '/images/destinations/aruba.png',
                        imageAlt: 'Aruba',
                        url: '/aruba/blog',
                    },
                    {
                        id: 3,
                        title: 'Maldives',
                        description:
                            'The Maldives is a tropical nation in the Indian Ocean composed of 26 ring-shaped atolls, which are made up of more than 1,000 coral islands. It’s known for its beaches, blue lagoons and extensive reefs.',
                        imageSrc: '/images/destinations/maldives.png',
                        imageAlt: 'Maldives',
                        url: '/maldives/blog',
                    },
                ]}
                CustomRenderer={DestinationCard}
                Image={Image}
                itemName="Destination"
                itemProps={{ lazyLoad }}
            />

            {!showAll && (
                <Link to="/destinations">
                    <Button margin="2rem 0 0 0">View all destinations</Button>
                </Link>
            )}
        </Box>
    );
};

export default DestinationCardCarousel;
