"use client"

import { Box, Button, Link } from "@repo/ui"
import { useWindowSize } from "@repo/utils/hooksUtils"
import * as React from "react"
import TopMenuMobile from "./TopMenuMobile"

const TopMenuRightSide = () => {
  const { width } = useWindowSize()

  if (width <= 700) {
    return <TopMenuMobile />
  }

  return (
    <Box>
      <Link style={{ color: '#fff', fontWeight: "bold !important" }} to="/">
        <Button variant="text">Home</Button>
      </Link>
      <Link style={{ color: '#fff', fontWeight: "bold !important" }} to="/blog">
        <Button variant="text">Blog</Button>
      </Link>
      <Link style={{ color: '#fff', fontWeight: "bold !important" }} to="/destinations">
        <Button variant="text">Destinations</Button>
      </Link>
      <Link style={{ color: '#fff', fontWeight: "bold !important" }} to="/about">
        <Button variant="text">About</Button>
      </Link>
      <Link style={{ color: '#fff', fontWeight: "bold !important" }} to="/contact">
        <Button variant="text">Contact</Button>
      </Link>
    </Box>
  )
}

export default TopMenuRightSide
