import appCategories from './categories';

const domain = process.env.NEXT_PUBLIC__PLATFORM_URL || 'https://tropicaltravelling.com/';

export const defaultSeo = {
    name: 'Tropical Travelling',
    title: 'Tropical Travelling - Your ultimate guide to exploring the most exotic and stunning places on Earth',
    description: 'Your ultimate guide to exploring the most exotic and stunning places on Earth',
    category: 'Travel',
    pageUrl: domain,
    logoUrl: `${domain}/logo.png`,
    logoWidth: 256,
    logoHeight: 102,
    imageUrl: `${domain}/facebook-cover.png`,
    imageWidth: 1200,
    imageHeight: 630,
    social: [
        'https://www.instagram.com/stefan.burscher',
        'https://www.facebook.com/stefan.burscher.3',
        'https://www.youtube.com/channel/UCGn-A3v_inLOf3Hx-ld5a5Q',
    ],
    twitter: undefined,
    alternateName: ['Tropical Travelling', 'Tropical Travel'],
    contactEmail: undefined,
    rssFeed: [{ url: '/rss/article-rss.xml' }],
    favicons: {
        icon: [
            { sizes: '32x32', url: '/favicon-32x32.png', type: 'image/png' },
            { sizes: '16x16', url: '/favicon-16x16.png', type: 'image/png' },
        ],
        apple: [{ url: '/apple-touch-icon.png' }],
    },
};

export const blogAuthor = {
    name: 'Stefan Burscher',
    image: `${domain}/logo192.png`,
    url: '/about',
};

export const footerLinks = [
    {
        title: 'Company',
        links: [
            { title: 'About Us', url: '/about' },
            { title: 'Contact Us', url: '/contact' },
        ],
    },
    {
        title: 'Resources',
        links: [
            { title: 'Destinations', url: '/destinations' },
            { title: 'Blog', url: '/blog' },
        ],
    },
    {
        title: 'Categories',
        links: appCategories.subjects.map((subj: any) => ({
            title: subj.subject,
            url: `/${subj.urlSlug}/blog`,
        })),
    },
];
